import React, {useEffect} from 'react';
import {compose} from 'recompose';
import Container from '../components/container';
import Layout from '../components/layout';
import {withAuthorization, withEmailVerification} from '../components/Session';
import Messages from '../components/Messages';

const HomePageBase = () => {
	useEffect(() => {
		({username: name} = JSON.parse(localStorage.getItem('authUser')) || '');
	});
	return (
		<React.Fragment>
			<h1>Home Page</h1>
			<p>The Home Page is accessible by every signed in user.</p>
			<p>Welcome {name}!</p>
			<Messages />
		</React.Fragment>
	);
};

const condition = (authUser) => !!authUser;

const HomePage = compose(
	withEmailVerification,
	withAuthorization(condition),
)(HomePageBase);

export default (props) => (
	<Layout location={props.location}>
		<Container withSidebar={false}>
			<HomePage />
		</Container>
	</Layout>
);
